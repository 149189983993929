import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import styles from './index.module.css';
import Context from '../../utils/context';

export default function SimpleHeader({ logoClickable, openInNewTab, logo = false, showLogin = true }) {
  const context = useContext(Context);
  const isLoggedIn = context.auth.isLoggedIn();

  const renderLogo = () => {
    return (
      <>
        <img
          alt='logo'
          id='logo'
          src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/PatientPartner+Logo_Teal.png'
          data-gtm-click-label='Nav Logo Embedded/iFrame'
        />
      </>
    );
  };
  return (
    <header id='hdr-content' className={styles.simple_header}>
      <div className={`container`}>
        <div className={styles.header_row}>
          <div className={styles.logo_col}>
            <div className={styles.hdr_logo_lg}>
              {logoClickable ? (
                openInNewTab ? (
                  <a target='_blank' href='https://www.patientpartner.com/'>
                    {renderLogo()}
                  </a>
                ) : (
                  <a href='/'>{renderLogo()}</a>
                )
              ) : (
                renderLogo()
              )}
              {logo && <img src={logo} alt='logo' />}
            </div>
          </div>
          {!isLoggedIn && (
            <div className={styles.login_col}>
              <Link to='/member/login'>
                <button className={styles.login_btn} data-gtm-click-label='Nav Login'>
                  Login
                </button>
              </Link>
            </div>
          )}
        </div>
      </div>
    </header>
  );
}
