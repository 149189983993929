import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import styles from './age.module.css';
import ErrorMessage from '../../ui_kit/utilities/error_message';

export default function Age({
  title = 'What is your age?',
  para = 'We’ll match you with someone who is in the same age range as you.',
  answers,
  onBack,
  onSubmit,
  cta = 'Next',
  gtm,
}) {
  const [validDateOfBirth, setValidDateOfBirth] = useState(true);
  const [dateOfBirthError, setDateOfBirthError] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [dateOfBirth, setDateOfBirth] = useState(
    answers.date_of_birth !== null && answers.date_of_birth !== undefined ? new Date(answers.date_of_birth) : ''
  );

  const onChange = (date) => {
    const normalizedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    setDateOfBirth(normalizedDate);
  };

  const validate = (date) => {
    if (date === '' || date === null || date === undefined) {
      setValidDateOfBirth(false);
      setDateOfBirthError('Please enter a valid date in the format MM/DD/YYYY');

      return;
    }

    if (date > new Date()) {
      setValidDateOfBirth(false);
      setDateOfBirthError('Please enter a valid date before today in the format MM/DD/YYYY');

      return;
    }

    if (date < new Date('01/01/1900')) {
      setValidDateOfBirth(false);
      setDateOfBirthError('Please enter a valid date after 01/01/1900 in the format MM/DD/YYYY');

      return;
    }

    setDateOfBirthError('');
    setDisabled(false);
    setValidDateOfBirth(true);
  };

  return (
    <div className='row'>
      <div className='col-sm-2'></div>
      <div className='col-sm-8'>
        <div className='age-choice'>
          <div>
            <h5 className='mobile-marg-20 marg-bottom-10'>{title}</h5>
            <p>{para}</p>
            <DatePicker
              className={styles.tile}
              popperPlacement='top'
              dateFormat='MM/dd/yyyy'
              onCalendarClose={() => validate(dateOfBirth)}
              placeholderText='MM/DD/YYYY'
              selected={dateOfBirth}
              onChange={(date) => onChange(date)}
              onBlur={() => validate(dateOfBirth)}
              wrapperClassName={styles.date_picker_wrapper}
            />
            {validDateOfBirth === false && <ErrorMessage error={dateOfBirthError} />}
          </div>
          <div className='row button-holder'>
            {onBack && (
              <button className='back col-6' type='button' onClick={onBack} data-gtm-click-label={`${gtm} Age Back`}>
                Back
              </button>
            )}
            <button
              disabled={disabled}
              className={`gtm-preexisting-conditions submit col-6`}
              type='submit'
              onClick={() => onSubmit({ ...answers, date_of_birth: dateOfBirth })}
              data-gtm-click-label={`${gtm} Age Submit`}
            >
              {cta}
            </button>
          </div>
        </div>
      </div>
      <div className='col-sm-2'></div>
    </div>
  );
}
