import React, { useState, useContext, useEffect } from 'react';
import { useHistory, Switch, Route, useRouteMatch, useParams } from 'react-router-dom';
import Context from '../../../utils/context';
import SignUp from '../../sign_up';
import Age from '../age/age';
import PatientGender from '../patient_partner/patient_gender';
import MentorConversationTopics from '../../surgeon/referred_mentor_match/pre_conversation_questionnaire/myelofibrosis/mentor_conversation_topics';
import HowAreYouFeeling from '../../questionnaire/cosmetic/how_are_you_feeling';
import LoadingScreen from '../../loading_screen';
import WhatProcedure from '../patient_partner/what_procedure';
import { getSurgeon } from '../../../api/surgeons';
import HealthcareJourney from '../patient_partner/healthcare_journey';
import MentorMatch from '../../surgeon/referred_mentor_match/pre_conversation_questionnaire/mentor_match';
import {
  getSurgeonReferredMentorMatch,
  updateSurgeonReferredMentorMatch,
  addMatchedMentor,
  createSurgeonReferredMentorMatch,
} from '../../../api/surgeon_referred_mentor_matches';
import { getSurgeonReferredMentorMatchFromProfile } from './../../../api/profiles';
import styles from './index.module.css';

export default function MedtronicQuestionnaire({ headerContent = <></> }) {
  const context = useContext(Context);
  const { id } = useParams();
  const history = useHistory();
  const [currentBirthday, setCurrentBirthday] = useState(
    context.auth.isLoggedIn() ? context.auth.getCurrentUser().date_of_birth : null
  );
  const [currentGender, setCurrentGender] = useState(
    context.auth.isLoggedIn() ? context.auth.getCurrentUser().gender : null
  );
  const [currentJourney, setCurrentJourney] = useState('');
  const [surgery, setSurgery] = useState(null);
  const [surgeon, setSurgeon] = useState({});
  const [currentlyFeeling, setCurrentlyFeeling] = useState('');
  const [availableSurgeries, setAvailableSurgeries] = useState([]);
  const [mentorFound, setMentorFound] = useState(false);
  const [mentorConversationTopics, setMentorConversationTopics] = useState(() => {
    const saved = sessionStorage.getItem('mentorConversationTopics');
    return saved ? JSON.parse(saved) : [];
  });
  const [surgeonReferredMentorMatch, setSurgeonReferredMentorMatch] = useState(() => {
    const saved = sessionStorage.getItem('surgeonReferredMentorMatch');
    return saved ? JSON.parse(saved) : '';
  });
  const [patientPartner, setPatientPartner] = useState(() => {
    const saved = sessionStorage.getItem('patientPartner');
    return saved ? JSON.parse(saved) : '';
  });

  let { path } = useRouteMatch();

  path = path.replace(`:id`, id);

  useEffect(() => {
    if (mentorFound && patientPartner) {
      history.push(`${path}/patient-partner`);
    }
  }, [mentorFound, patientPartner]);

  const findMentor = () => {
    addMatchedMentor(surgeonReferredMentorMatch.uuid, (data) => {
      setPatientPartner(data);
      sessionStorage.setItem('patientPartner', JSON.stringify(data));
      setMentorFound(true);
    });
  };

  const scrollToTopOfPage = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const createAndRedirect = () => {
    createSurgeonReferredMentorMatch(
      {
        surgeon_id: gon.medtronic_surgeon_id,
        surgery: null,
      },
      (match) => {
        history.push(`/member/mentor-matches/${match.uuid}/dr-moises-jacobs/surgery`);
        setTimeout(() => {
          scrollToTopOfPage();
        }, 0);
      }
    );
  };

  const update = (params) => {
    getSurgeonReferredMentorMatch(id, (data) => {
      updateSurgeonReferredMentorMatch(data.uuid, params, (data) => {
        setSurgeonReferredMentorMatch(data);
        sessionStorage.setItem('surgeonReferredMentorMatch', JSON.stringify(data));
      });
    });
  };

  useEffect(() => {
    if (context.auth.isLoggedIn()) {
      getSurgeon(gon.medtronic_surgeon_id, (data) => {
        setSurgeon(data);
        setAvailableSurgeries(data.procedure_types);
      });
      getSurgeonReferredMentorMatchFromProfile({ surgeon_id: gon.medtronic_surgeon_id, surgery: surgery }, (match) => {
        setSurgeonReferredMentorMatch(match);
        if (history.location.pathname === '/member/join/support/dr-moises-jacobs') {
          if (match.surgery !== null) {
            history.push(`/member/mentor-matches/${match.uuid}/dr-moises-jacobs/gender`);
          } else {
            history.push(`/member/mentor-matches/${match.uuid}/dr-moises-jacobs/surgery`);
          }
        }
      });
    }
  }, []);

  let steps = [
    'surgery',
    'gender',
    'age',
    'healthcare-journey',
    'currently-feeling',
    'conversation-topics',
    'loading-patient-partner',
  ];

  let nextStep = steps[steps.findIndex((r) => window.location.pathname.includes(r)) + 1];
  let previousStep = steps[steps.findIndex((r) => window.location.pathname.includes(r)) - 1];

  if (!nextStep) {
    nextStep = 'patient-partner';
  }

  if (!previousStep) {
    previousStep = 'surgery';
  }

  return (
    <div
      className={styles.questionnaire_ottr}
      id='questionnaire'
      style={{ backgroundColor: '#f5f7f9', maxWidth: '100%' }}
    >
      <div className={`${styles.questionnaire_content}`}>
        <div className={`${styles.cont_row}`}>
          <div
            className={`col-md-8`}
            style={{ paddingLeft: '15px', paddingRight: '15px', flex: '0 0 100%', maxWidth: '100%' }}
          >
            {headerContent}
            <Switch>
              <Route exact path={`${path}`}>
                <SignUp
                  onSuccess={createAndRedirect}
                  params={{
                    keap_force_sync: true,
                    origin: 'Medtronic Landing Page',
                    procedure: 'Weight Loss',
                    surgery: null,
                  }}
                  headline={
                    <p style={{ color: '#74ccd3', fontWeight: '700', fontSize: '25px' }}>
                      Get matched with your mentor!
                    </p>
                  }
                  para={`Answer a few brief questions to create your account and get your mentor match.`}
                  embedded
                  alignLeft
                  light={false}
                  captureFirstLastName
                  captureLocation
                />
              </Route>
            </Switch>

            <Switch>
              <Route exact path={`${path}/surgery`}>
                <WhatProcedure
                  head={'What surgery or treatment are you considering?'}
                  para='This will help pair you with a mentor who has similar experiences.'
                  selected={surgery}
                  choices={[...availableSurgeries]}
                  label={'Select one'}
                  onBack={() => {
                    history.push(`${path}/${previousStep}`);
                  }}
                  onSubmit={(values) => {
                    setSurgery(values[0]);
                    update({ surgery: values[0] });
                    context.auth.userUpdate({ procedure: 'Weight Loss', surgery: values[0] }, () =>
                      history.push(`${path}/${nextStep}`)
                    );
                  }}
                />
              </Route>
            </Switch>

            <Switch>
              <Route exact path={`${path}/gender`}>
                <PatientGender
                  answers={{ gender: currentGender }}
                  onSubmit={(answers) => {
                    setCurrentGender(answers.gender);
                    context.auth.userUpdate({ gender: answers.gender }, () => history.push(`${path}/${nextStep}`));
                  }}
                  onBack={() => history.push(`${path}/${previousStep}`)}
                  gtm={''}
                />
              </Route>
            </Switch>

            <Switch>
              <Route exact path={`${path}/age`}>
                <Age
                  title='What is your birthday?'
                  para={`We'll match you with a mentor in your age group.`}
                  answers={{ date_of_birth: currentBirthday }}
                  onSubmit={(answers) => {
                    setCurrentBirthday(answers.date_of_birth);
                    context.auth.userUpdate({ date_of_birth: answers.date_of_birth }, () =>
                      history.push(`${path}/${nextStep}`)
                    );
                  }}
                  onBack={() => history.push(`${path}/${previousStep}`)}
                  gtm={''}
                  cta='Next'
                />
              </Route>
            </Switch>

            <Switch>
              <Route exact path={`${path}/healthcare-journey`}>
                <HealthcareJourney
                  title={`Where are you in your healthcare journey?`}
                  para={`We'll let your mentor know where you are in your journey. Select the best match.`}
                  answers={{ healthcare_journey: currentJourney }}
                  onSubmit={(answers) => {
                    setCurrentJourney(answers.currentJourney[0]);
                    update({ healthcare_journey: answers.currentJourney[0] });
                    history.push(`${path}/${nextStep}`);
                  }}
                  onBack={() => history.push(`${path}/${previousStep}`)}
                  gtm={''}
                  allowOther={false}
                  cta='Next'
                />
              </Route>
            </Switch>

            <Switch>
              <Route exact path={`${path}/currently-feeling`}>
                <HowAreYouFeeling
                  answers={{ currentlyFeeling: currentlyFeeling }}
                  numberRating={true}
                  onSubmit={(answers) => {
                    setCurrentlyFeeling(answers.currentlyFeeling);
                    update({ currently_feeling: answers.currentlyFeeling });
                    history.push(`${path}/${nextStep}`);
                  }}
                  icons={[
                    { text: 'Very nervous' },
                    { text: 'Nervous' },
                    { text: 'Unsure' },
                    { text: 'Excited' },
                    { text: 'Very excited' },
                  ]}
                  textLabels={['Very Nervous', 'Nervous', 'Unsure', 'Excited', 'Very Excited']}
                  onBack={() => history.push(`${path}/${previousStep}`)}
                />
              </Route>
            </Switch>

            <Switch>
              <Route exact path={`${path}/conversation-topics`}>
                <MentorConversationTopics
                  answers={mentorConversationTopics}
                  showOther={true}
                  title={`What would you like to talk to your mentor about? (Select all that apply.)`}
                  para={`We'll let your mentor know some topics you’d like to talk about.`}
                  choices={[
                    'Pros and cons of the procedure',
                    'Procedure preparation tips',
                    'Recovery tips',
                    'Tips for finding the best doctor',
                  ]}
                  onSubmit={(answers) => {
                    update({ mentor_conversation_topics: answers['mentorConversationTopics'] });
                    setMentorConversationTopics(answers['mentorConversationTopics']);
                    sessionStorage.setItem(
                      'mentorConversationTopics',
                      JSON.stringify(answers['mentorConversationTopics'])
                    );
                    history.push(`${path}/${nextStep}`);

                    const timer = setTimeout(() => {
                      findMentor();
                    }, 3000);
                    return () => clearTimeout(timer);
                  }}
                  onBack={() => history.push(`${path}/${previousStep}`)}
                />
              </Route>
            </Switch>

            <Switch>
              <Route exact path={`${path}/loading-patient-partner`}>
                <LoadingScreen
                  title={'Finding your mentor...'}
                  content={
                    'Get ready to meet a real patient who will answer your questions and support you through your journey!'
                  }
                />
              </Route>
            </Switch>

            <Switch>
              <Route exact path={`${path}/patient-partner`}>
                <MentorMatch
                  patientPartner={patientPartner}
                  id={id}
                  whatsNextMessage={(p) => {
                    return `Send your first message to ${p.name} and get answers to questions about the procedure, recovery, and their experience.`;
                  }}
                  callback={(conversation) => {
                    updateSurgeonReferredMentorMatch(
                      surgeonReferredMentorMatch.uuid,
                      { conversation_id: conversation.id },
                      () => {
                        let firstMessage = '';
                        if (mentorConversationTopics && mentorConversationTopics.length > 0) {
                          if (mentorConversationTopics.length === 1 || mentorConversationTopics.length === 2) {
                            firstMessage =
                              'Hi ' +
                              patientPartner.name +
                              ', I would like to chat about ' +
                              (mentorConversationTopics || []).join(' and ').toLowerCase();
                          } else {
                            firstMessage =
                              'Hi ' +
                              patientPartner.name +
                              ', I would like to chat about ' +
                              (mentorConversationTopics || []).slice(0, -1).join(', ').toLowerCase() +
                              ' and ' +
                              mentorConversationTopics[mentorConversationTopics.length - 1].toLowerCase();
                          }
                        }
                        history.push(`/member/conversations/${conversation.uuid}?firstMessage=${firstMessage}`);
                      }
                    );
                  }}
                />
              </Route>
            </Switch>
          </div>
          <div className='col-md-2'></div>
        </div>
      </div>
    </div>
  );
}
