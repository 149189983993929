import React, { createContext, useContext, useState, useEffect } from 'react';
import Context from './context';

const QuestionnaireModalContext = createContext();

export const useQuestionnaireModal = () => useContext(QuestionnaireModalContext);

export const QuestionnaireModalProvider = ({ children }) => {
  const context = useContext(Context);
  const [isModalVisible, setModalVisible] = useState(false);
  const currentUser = context.auth.getCurrentUser();
  const isPatient = currentUser.current_profile_type === 'Patient';
  const allowedPaths = gon.allowed_paths_before_questionnaire || [];
  const currentPath = window.location.pathname;

  useEffect(() => {
    // Show the modal if the current path is not in the allowed paths
    const matchedWithMentor =
      sessionStorage.getItem('patientPartner') !== null || currentUser.matched_with_mentor == true;
    const shouldShowModal =
      !allowedPaths.some((allowedPath) => currentPath.startsWith(allowedPath)) && isPatient && !matchedWithMentor;
    setModalVisible(shouldShowModal);
  }, [currentPath, allowedPaths]);

  return <QuestionnaireModalContext.Provider value={{ isModalVisible }}>{children}</QuestionnaireModalContext.Provider>;
};
