import React, { useState, useContext, useEffect } from 'react';
import { useHistory, Switch, Route, useRouteMatch, useParams } from 'react-router-dom';
import Context from '../../../utils/context';
import SignUp from '../../sign_up';
import Age from '../age/age';
import PatientGender from '../patient_partner/patient_gender';
import MentorConversationTopics from '../../surgeon/referred_mentor_match/pre_conversation_questionnaire/myelofibrosis/mentor_conversation_topics';
import LoadingScreen from '../../loading_screen';
import HealthcareJourney from '../patient_partner/healthcare_journey';
import ActivityLevel from '../activity_level';
import MentorMatch from '../../surgeon/referred_mentor_match/pre_conversation_questionnaire/mentor_match';
import {
  getSurgeonReferredMentorMatch,
  updateSurgeonReferredMentorMatch,
  addMatchedMentor,
  createSurgeonReferredMentorMatch,
} from '../../../api/surgeon_referred_mentor_matches';
import { getSurgeonReferredMentorMatchFromProfile } from './../../../api/profiles';
import styles from './index.module.css';

export default function OssioQuestionnaire({ headerContent = <></> }) {
  const context = useContext(Context);
  const { id } = useParams();
  const history = useHistory();
  const [currentBirthday, setCurrentBirthday] = useState(
    context.auth.isLoggedIn() ? context.auth.getCurrentUser().date_of_birth : null
  );
  const [currentGender, setCurrentGender] = useState(
    context.auth.isLoggedIn() ? context.auth.getCurrentUser().gender : null
  );
  const [currentJourney, setCurrentJourney] = useState('');
  const [currentActivityLevel, setCurrentActivityLevel] = useState('');
  const [mentorFound, setMentorFound] = useState(false);
  const [mentorConversationTopics, setMentorConversationTopics] = useState(() => {
    const saved = sessionStorage.getItem('mentorConversationTopics');
    return saved ? JSON.parse(saved) : [];
  });
  const [surgeonReferredMentorMatch, setSurgeonReferredMentorMatch] = useState(() => {
    const saved = sessionStorage.getItem('surgeonReferredMentorMatch');
    return saved ? JSON.parse(saved) : '';
  });
  const [patientPartner, setPatientPartner] = useState(() => {
    const saved = sessionStorage.getItem('patientPartner');
    return saved ? JSON.parse(saved) : '';
  });

  let { path } = useRouteMatch();

  path = path.replace(`:id`, id);

  useEffect(() => {
    if (mentorFound && patientPartner) {
      history.push(`${path}/patient-partner`);
    }
  }, [mentorFound, patientPartner]);

  const findMentor = () => {
    addMatchedMentor(surgeonReferredMentorMatch.uuid, (data) => {
      setPatientPartner(data);
      sessionStorage.setItem('patientPartner', JSON.stringify(data));
      setMentorFound(true);
    });
  };

  const scrollToTopOfPage = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const createAndRedirect = () => {
    createSurgeonReferredMentorMatch(
      {
        surgeon_id: gon.ossio_surgeon_id,
        surgery: 'OSSIOfiber Bunion Correction Procedure',
      },
      (match) => {
        history.push(`/member/mentor-matches/${match.uuid}/ossio/gender`);
        setTimeout(() => {
          scrollToTopOfPage();
        }, 0);
      }
    );
  };

  const update = (params) => {
    getSurgeonReferredMentorMatch(id, (data) => {
      updateSurgeonReferredMentorMatch(data.uuid, params, (data) => {
        setSurgeonReferredMentorMatch(data);
        sessionStorage.setItem('surgeonReferredMentorMatch', JSON.stringify(data));
      });
    });
  };

  useEffect(() => {
    if (context.auth.isLoggedIn()) {
      getSurgeonReferredMentorMatchFromProfile(
        { surgeon_id: gon.ossio_surgeon_id, surgery: 'OSSIOfiber Bunion Correction Procedure' },
        (match) => {
          setSurgeonReferredMentorMatch(match);
          if (history.location.pathname === '/member/join/support/bunions') {
            history.push(`/member/mentor-matches/${match.uuid}/ossio/gender`);
          }
        }
      );
    }
  }, []);

  let steps = [
    'gender',
    'age',
    'healthcare-journey',
    'activity-level',
    'mentor-conversation-topics',
    'loading-patient-partner',
  ];

  let nextStep = steps[steps.findIndex((r) => window.location.pathname.includes(r)) + 1];
  let previousStep = steps[steps.findIndex((r) => window.location.pathname.includes(r)) - 1];

  if (!nextStep) {
    nextStep = 'patient-partner';
  }

  if (!previousStep) {
    previousStep = 'gender';
  }

  return (
    <div
      className={styles.questionnaire_ottr}
      id='questionnaire'
      style={{ backgroundColor: '#f5f7f9', maxWidth: '100%' }}
    >
      <div className={`${styles.questionnaire_content}`}>
        <div className={`${styles.cont_row}`}>
          <div
            className={`col-md-8`}
            style={{ paddingLeft: '15px', paddingRight: '15px', flex: '0 0 100%', maxWidth: '100%' }}
          >
            {headerContent}
            <Switch>
              <Route exact path={`${path}`}>
                <SignUp
                  onSuccess={createAndRedirect}
                  params={{
                    keap_force_sync: true,
                    origin: 'Ossio Landing Page',
                    procedure: 'Bunions',
                    surgery: 'OSSIOfiber Bunion Correction Procedure',
                  }}
                  headline={
                    <p style={{ color: '#63BC47', fontWeight: '700', fontSize: '25px' }}>
                      Get matched with your mentor!
                    </p>
                  }
                  para={`Answer a few brief questions to create your account and get your mentor match.`}
                  embedded
                  alignLeft
                  light={false}
                  captureFirstLastName
                  captureLocation
                />
              </Route>
            </Switch>

            <Switch>
              <Route exact path={`${path}/gender`}>
                <PatientGender
                  answers={{ gender: currentGender }}
                  onSubmit={(answers) => {
                    setCurrentGender(answers.gender);
                    context.auth.userUpdate({ gender: answers.gender }, () => history.push(`${path}/${nextStep}`));
                  }}
                  onBack={() => history.push(`${path}/${previousStep}`)}
                  gtm={''}
                />
              </Route>
            </Switch>

            <Switch>
              <Route exact path={`${path}/age`}>
                <Age
                  title='What is your birthday?'
                  para={`We'll match you with a mentor in your age group.`}
                  answers={{ date_of_birth: currentBirthday }}
                  onSubmit={(answers) => {
                    setCurrentBirthday(answers.date_of_birth);
                    context.auth.userUpdate({ date_of_birth: answers.date_of_birth }, () =>
                      history.push(`${path}/${nextStep}`)
                    );
                  }}
                  onBack={() => history.push(`${path}/${previousStep}`)}
                  gtm={''}
                  cta='Next'
                />
              </Route>
            </Switch>

            <Switch>
              <Route exact path={`${path}/healthcare-journey`}>
                <HealthcareJourney
                  title={`Where are you in your healthcare journey?`}
                  para={`We'll let your mentor know where you are in your journey. Select the best match.`}
                  answers={{ healthcare_journey: currentJourney }}
                  onSubmit={(answers) => {
                    setCurrentJourney(answers.currentJourney[0]);
                    update({ healthcare_journey: answers.currentJourney[0] });
                    history.push(`${path}/${nextStep}`);
                  }}
                  onBack={() => history.push(`${path}/${previousStep}`)}
                  gtm={''}
                  allowOther={false}
                  cta='Next'
                />
              </Route>
            </Switch>

            <Switch>
              <Route exact path={`${path}/activity-level`}>
                <ActivityLevel
                  title={`How would you describe your current activity level?`}
                  para={`Matching you with a mentor who shares a similar activity level helps ensure more personalized support and relatable advice.`}
                  answers={{ activityLevel: currentActivityLevel }}
                  onSubmit={(answers) => {
                    setCurrentActivityLevel(answers.activityLevel);
                    update({ activity_level: answers.activityLevel });
                    history.push(`${path}/${nextStep}`);
                  }}
                  onBack={() => history.push(`${path}/${previousStep}`)}
                  gtm={''}
                  choices={[
                    {
                      value: 'Very Active',
                      subTitle: 'Regular, intense exercise',
                      icon: (
                        <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path
                            d='M18.25 5.5C19.6307 5.5 20.75 4.38071 20.75 3C20.75 1.61929 19.6307 0.5 18.25 0.5C16.8693 0.5 15.75 1.61929 15.75 3C15.75 4.38071 16.8693 5.5 18.25 5.5Z'
                            fill='black'
                          />
                          <path
                            d='M16.7213 11.7112C17.0082 12.1388 17.4198 12.4678 17.9002 12.6534C18.3805 12.839 18.9063 12.8723 19.4062 12.7487L23.5513 11.7125L22.9475 9.28749L18.8025 10.3237L17.0788 7.73999C16.7105 7.1882 16.1389 6.80446 15.4888 6.67249L10.6813 5.70999C10.1434 5.60206 9.58503 5.67419 9.09229 5.91525C8.59955 6.15631 8.19988 6.55289 7.955 7.04374L5.8825 11.1887L8.11875 12.3075L10.1913 8.16124L12.65 8.65374L6.2925 19.25H0.75V21.75H6.2925C7.165 21.75 7.98625 21.285 8.43625 20.5362L10.8338 16.5412L17.295 17.8337L19.565 24.645L21.935 23.8537L19.6663 17.0437C19.5262 16.6256 19.2785 16.2518 18.948 15.96C18.6175 15.6681 18.216 15.4685 17.7838 15.3812L13.985 14.6225L16.2 10.93L16.7213 11.7112Z'
                            fill='black'
                          />
                        </svg>
                      ),
                    },
                    {
                      value: 'Moderately Active',
                      subTitle: 'Exercise a few times a week',
                      icon: (
                        <svg
                          width='30'
                          height='30'
                          viewBox='0 0 24 24'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='#000000'
                        >
                          <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
                          <g id='SVGRepo_tracerCarrier' strokeLinecap='round' strokeLinejoin='round'></g>
                          <g id='SVGRepo_iconCarrier'>
                            <path d='M12.1 3A1.9 1.9 0 1 1 14 4.9 1.898 1.898 0 0 1 12.1 3zm2.568 4.893c.26-1.262-1.399-1.861-2.894-2.385L7.09 6.71l.577 4.154c0 .708 1.611.489 1.587-.049l-.39-2.71 2.628-.48-.998 4.92 3.602 4.179-1.469 4.463a.95.95 0 0 0 .39 1.294c.523.196 1.124-.207 1.486-.923.052-.104 1.904-5.127 1.904-5.127l-2.818-3.236 1.08-5.303zm-5.974 8.848l-3.234.528a1.033 1.033 0 0 0-.752 1.158c.035.539.737.88 1.315.802l3.36-.662 2.54-2.831-1.174-1.361zm8.605-7.74l-1.954.578-.374 1.837 2.865-.781a.881.881 0 0 0-.537-1.633z'></path>
                            <path fill='none' d='M0 0h24v24H0z'></path>
                          </g>
                        </svg>
                      ),
                    },
                    {
                      value: 'Lightly Active',
                      subTitle: 'Daily movement, no structured exercise',
                      icon: (
                        <svg width='28' height='23' viewBox='0 0 28 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path
                            d='M27.6591 12.0836L27.64 12.0618L27.5609 11.9827L24.9128 9.33727C24.7271 9.13545 24.485 8.99428 24.2179 8.93219C23.9508 8.87011 23.6712 8.88998 23.4156 8.98922C23.16 9.08847 22.9402 9.26247 22.785 9.48851C22.6298 9.71456 22.5463 9.98215 22.5455 10.2564V13.3873L17.0909 18.8527V6.18181C17.0909 4.73517 16.5163 3.34779 15.4933 2.32486C14.4704 1.30194 13.083 0.727264 11.6364 0.727264C10.1898 0.727264 8.80237 1.30194 7.77944 2.32486C6.75651 3.34779 6.18184 4.73517 6.18184 6.18181C4.73521 6.18181 3.34782 6.75648 2.32489 7.77941C1.30197 8.80233 0.727295 10.1897 0.727295 11.6364C0.727295 13.083 1.30197 14.4704 2.32489 15.4933C3.34782 16.5162 4.73521 17.0909 6.18184 17.0909V19.8182C6.18184 20.5415 6.46918 21.2352 6.98064 21.7466C7.4921 22.2581 8.1858 22.5454 8.90911 22.5454H14.3637C14.8227 22.5398 15.2726 22.4164 15.6703 22.1872C16.0681 21.958 16.4004 21.6306 16.6355 21.2364L23.5273 14.3364H26.4209C26.6988 14.3811 26.9838 14.3387 27.2366 14.2151C27.4895 14.0914 27.6979 13.8926 27.8332 13.6458C27.9686 13.399 28.0242 13.1163 27.9926 12.8366C27.9609 12.557 27.8435 12.2939 27.6564 12.0836H27.6591ZM2.09093 11.6364C2.09093 10.5514 2.52194 9.51084 3.28913 8.74365C4.05633 7.97645 5.09686 7.54545 6.18184 7.54545V15.7273C5.09686 15.7273 4.05633 15.2963 3.28913 14.5291C2.52194 13.7619 2.09093 12.7213 2.09093 11.6364ZM7.54548 6.18181C7.54548 5.09683 7.97648 4.05629 8.74368 3.2891C9.51087 2.52191 10.5514 2.0909 11.6364 2.0909C12.7214 2.0909 13.7619 2.52191 14.5291 3.2891C15.2963 4.05629 15.7273 5.09683 15.7273 6.18181H7.54548Z'
                            fill='black'
                          />
                        </svg>
                      ),
                    },
                    {
                      value: 'Limited Activity',
                      subTitle: 'Light, low-impact movement',
                      icon: (
                        <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path
                            d='M22.9327 1.34697e-05C22.6742 0.0277992 22.4327 0.142047 22.2473 0.324244L15 7.57271L11.2142 3.78463C11.1051 3.67561 10.9756 3.58914 10.8331 3.53017C10.6906 3.47119 10.5379 3.44087 10.3836 3.44092C10.2294 3.44097 10.0767 3.47141 9.93421 3.53048C9.79174 3.58955 9.66229 3.67611 9.55327 3.78521C9.33309 4.00554 9.20945 4.30432 9.20956 4.61581C9.20961 4.77004 9.24005 4.92275 9.29912 5.06523C9.35819 5.2077 9.44475 5.33714 9.55385 5.44617L13.3408 9.23078H3.46154C2.54348 9.23078 1.66303 9.59548 1.01386 10.2446C0.364697 10.8938 0 11.7743 0 12.6923L0 26.5385C0 27.4565 0.364697 28.337 1.01386 28.9862C1.66303 29.6353 2.54348 30 3.46154 30H26.5385C27.4565 30 28.337 29.6353 28.9861 28.9862C29.6353 28.337 30 27.4565 30 26.5385V12.6923C30 11.7743 29.6353 10.8938 28.9861 10.2446C28.337 9.59548 27.4565 9.23078 26.5385 9.23078H16.6581L23.9065 1.98347C24.0881 1.81466 24.2105 1.59186 24.2555 1.34806C24.3006 1.10425 24.2659 0.852426 24.1566 0.629875C24.0474 0.407324 23.8693 0.225887 23.6489 0.11244C23.4284 -0.00100605 23.1773 -0.0404282 22.9327 1.34697e-05ZM12.1154 11.5385C20.7058 11.5385 21.9231 11.5627 21.9231 19.6154C21.9231 27.6692 20.6215 27.6923 12.1154 27.6923C3.57 27.6923 2.30769 27.6069 2.30769 19.6154C2.30769 11.6262 3.57 11.5385 12.1154 11.5385ZM25.9615 14.9285C26.9608 14.9285 27.765 15.7316 27.765 16.7308C27.765 17.73 26.9608 18.5342 25.9615 18.5342C25.7245 18.5349 25.4897 18.4886 25.2707 18.3982C25.0516 18.3078 24.8525 18.175 24.6849 18.0074C24.5174 17.8398 24.3845 17.6407 24.2941 17.4217C24.2037 17.2026 24.1575 16.9678 24.1581 16.7308C24.1581 15.7316 24.9623 14.9273 25.9615 14.9273V14.9285ZM25.9615 19.5439C26.9608 19.5439 27.765 20.3469 27.765 21.3462C27.765 22.3454 26.9608 23.1496 25.9615 23.1496C25.7245 23.1502 25.4897 23.104 25.2707 23.0136C25.0516 22.9232 24.8525 22.7904 24.6849 22.6228C24.5174 22.4552 24.3845 22.2561 24.2941 22.037C24.2037 21.818 24.1575 21.5832 24.1581 21.3462C24.1581 20.3469 24.9623 19.5427 25.9615 19.5427V19.5439Z'
                            fill='black'
                          />
                        </svg>
                      ),
                    },
                    {
                      value: 'Not Active',
                      subTitle: 'Little to no physical activity',
                      icon: (
                        <svg height='30' width='30' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
                          <g id='SVGRepo_tracerCarrier' strokeLinecap='round' strokeLinejoin='round'></g>
                          <g id='SVGRepo_iconCarrier'>
                            <path
                              d='M6.5 11C7.06692 11.6303 7.75638 12 8.5 12C9.24362 12 9.93308 11.6303 10.5 11'
                              stroke='#1C274C'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                            ></path>
                            <path
                              d='M13.5 11C14.0669 11.6303 14.7564 12 15.5 12C16.2436 12 16.9331 11.6303 17.5 11'
                              stroke='#1C274C'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                            ></path>
                            <path
                              d='M13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16Z'
                              fill='#1C274C'
                            ></path>
                            <path
                              d='M17 4L20.4641 2L19 7.4641L22.4641 5.4641'
                              stroke='#1C274C'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            ></path>
                            <path
                              d='M14.0479 5.5L15.7799 6.5L13.0479 7.23205L14.7799 8.23205'
                              stroke='#1C274C'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            ></path>
                            <path
                              d='M22 12C22 17.5228 17.5228 22 12 22C10.1786 22 8.47087 21.513 7 20.6622M12 2C6.47715 2 2 6.47715 2 12C2 13.8214 2.48697 15.5291 3.33782 17'
                              stroke='#1C274C'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                            ></path>
                          </g>
                        </svg>
                      ),
                    },
                  ]}
                  cta='Next'
                />
              </Route>
            </Switch>

            <Switch>
              <Route exact path={`${path}/mentor-conversation-topics`}>
                <MentorConversationTopics
                  answers={mentorConversationTopics}
                  showOther={true}
                  title={`What would you like to talk to your mentor about? (Select all that apply.)`}
                  para={`We'll let your mentor know some topics you’d like to talk about.`}
                  choices={[
                    'Procedure preparation tips',
                    'OSSIOfiber® sensation',
                    'Recovery process and recommendations',
                    'Previous symptoms and result details',
                    'General questions and support',
                  ]}
                  onSubmit={(answers) => {
                    update({ mentor_conversation_topics: answers['mentorConversationTopics'] });
                    setMentorConversationTopics(answers['mentorConversationTopics']);
                    sessionStorage.setItem(
                      'mentorConversationTopics',
                      JSON.stringify(answers['mentorConversationTopics'])
                    );
                    history.push(`${path}/${nextStep}`);

                    const timer = setTimeout(() => {
                      findMentor();
                    }, 3000);
                    return () => clearTimeout(timer);
                  }}
                  onBack={() => history.push(`${path}/${previousStep}`)}
                />
              </Route>
            </Switch>

            <Switch>
              <Route exact path={`${path}/loading-patient-partner`}>
                <LoadingScreen
                  title={'Finding your mentor...'}
                  content={
                    'Get ready to meet a real patient who will answer your questions and support you through your journey!'
                  }
                />
              </Route>
            </Switch>

            <Switch>
              <Route exact path={`${path}/patient-partner`}>
                <MentorMatch
                  patientPartner={patientPartner}
                  id={id}
                  whatsNextMessage={(p) => {
                    return `Send your first message to ${p.name} and get answers to questions about the procedure, recovery, and their experience.`;
                  }}
                  callback={(conversation) => {
                    updateSurgeonReferredMentorMatch(
                      surgeonReferredMentorMatch.uuid,
                      { conversation_id: conversation.id },
                      () => {
                        let firstMessage = '';
                        if (mentorConversationTopics && mentorConversationTopics.length > 0) {
                          if (mentorConversationTopics.length === 1 || mentorConversationTopics.length === 2) {
                            firstMessage =
                              'Hi ' +
                              patientPartner.name +
                              ', I would like to chat about ' +
                              (mentorConversationTopics || []).join(' and ').toLowerCase();
                          } else {
                            firstMessage =
                              'Hi ' +
                              patientPartner.name +
                              ', I would like to chat about ' +
                              (mentorConversationTopics || []).slice(0, -1).join(', ').toLowerCase() +
                              ' and ' +
                              mentorConversationTopics[mentorConversationTopics.length - 1].toLowerCase();
                          }
                        }
                        history.push(`/member/conversations/${conversation.uuid}?firstMessage=${firstMessage}`);
                      }
                    );
                  }}
                />
              </Route>
            </Switch>
          </div>
          <div className='col-md-2'></div>
        </div>
      </div>
    </div>
  );
}
