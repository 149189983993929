import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ErrorMessage from '../ui_kit/utilities/error_message';
import PasswordInput from '../ui_kit/utilities/password_input';
import Context from '../../utils/context';
import { validateEmail } from '../../helpers';
import './index.css';
import RequestButton from '../ui_kit/utilities/request_button';

export default function SignIn({ defaultEmail, onSuccess }) {
  const [email, setEmail] = useState(defaultEmail || '');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const context = useContext(Context);

  const [error, setError] = useState('');

  var checkedEmail = validateEmail(email);

  useEffect(() => {
    if (checkedEmail === true && password !== '') {
      setIsDisabled('');
    }
  }, [password, email]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        onClick(() => {});
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [email, password, rememberMe]);

  const onClick = (afterAction) => {
    context.auth.userSignIn(
      email,
      password,
      rememberMe,
      (data) => {
        afterAction();
        onSuccess(data);
      },
      (errors) => {
        setError(errors.join(','));
        afterAction();
      }
    );
    setError('');
  };

  const validate = (event) => {
    event.preventDefault();
    if (checkedEmail === false && email !== '') {
      setError('Please enter a valid email');
    } else {
      setError('');
    }
  };

  return (
    <div className='container sign-in'>
      <div className='row h-100'>
        <div className='col-md-3 no-mobile'></div>
        <div className='col-md-6 mobile-margins'>
          <div className='registrations-container'>
            <h5 className='text-center marg-top-20 marg-bottom-10'>Welcome back!</h5>
            <p className='text-center login-title marg-bottom-20'>
              Connect with your PatientPartner one on one and get answers found no where else.
            </p>
            <div className='material-input'>
              <input
                type='email'
                name='email'
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                onBlur={validate}
                required
              />
              <span className='bar'></span>
              <label>Email</label>
            </div>
            {error && <ErrorMessage error={error} />}
            <PasswordInput password={password} setPassword={setPassword} skipPasswordValidation={true} />
            <div className='row marg-top-10'>
              <div className='col my-auto'>
                <p className={`text-center question-privacy marg-top-5`}>
                  <label className='custom-checkbox'>
                    <input
                      type='checkbox'
                      id='terms'
                      name='terms'
                      checked={rememberMe}
                      onChange={() => setRememberMe(!rememberMe)}
                    />
                    <span>Keep me logged in</span>
                  </label>
                </p>
              </div>
              <div className='col my-auto'>
                <Link style={{ textAlign: 'right' }} to='/member/forgot-password'>
                  <p className='forgot-pass'>Forgot password?</p>
                </Link>
              </div>
            </div>
            <RequestButton
              request={onClick}
              recaptcha={true}
              disabled={isDisabled}
              classnames={'marg-top-10 submit'}
              style={{ width: '100%' }}
              cta={'Log in'}
            />
            <p className='marg-top-50 text-center' style={{ fontSize: '16px' }}>
              Don't have an account?
            </p>
            <Link
              style={{ width: 'max-content' }}
              className='marg-center text-center marg-top-5 marg-bottom-10'
              to={{ pathname: '/member/join/support', state: window.location.search }}
            >
              <button className='liner'>Create account</button>
            </Link>
          </div>
        </div>
      </div>
      <div className='col-md-3 no-mobile'></div>
    </div>
  );
}
