import React, { useState, useEffect, useContext } from 'react';
import styles from './index.module.css';
import { getSurgeonReferredMentorMatches } from '../../../api/surgeon_referred_mentor_matches';
import StarRating from '../../ui_kit/utilities/star_rating';
import { VictoryPie } from 'victory';
import { Bar } from 'react-chartjs-2';
import Divider from '../../ui_kit/utilities/divider';
import Context from '../../../utils/context';
import { getPatientPartners } from '../../../api/patient_partners';
import { getNumReferrals, getConnectedPatients } from '../../../api/surgeon_administrative_assistants';
import '../../ui_kit/inputs/date_range/styles.css';
import '../../ui_kit/inputs/date_range/default.css';
import { DateRangePicker } from 'react-date-range';
import styles1 from '../patients/mentor_connect/index.module.css';
import { animated } from '@react-spring/web';
import Skeleton from '../../ui_kit/utilities/skeleton';
import { useClickAway } from 'react-use';
import IsScrolling from 'react-is-scrolling';
import ReactTooltip from 'react-tooltip';

function ProviderInsights({ isScrolling }) {
  const [surgeonReferredMentorMatches, setSurgeonReferredMentorMatches] = useState([]);
  const [conversationRating, setConversationRating] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [patientPartners, setPatientPartners] = useState([]);
  const graphicColor = ['#57CC99', '#00ACB7', '#FF6361', '#0085FF'];
  const context = useContext(Context);
  const user = context.auth.getCurrentUser();
  const viewport = context.viewport;
  const isMobile = viewport.getIsMobile();
  const [showDateRange, setShowDateRange] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [limitFiveMentors, setLimitFiveMentors] = useState(false);
  const [numReferrals, setNumReferrals] = useState();
  const [numConnectedPatients, setNumConnectedPatients] = useState();

  const countSum = (arr) => {
    let sum = 0;
    if (arr) {
      for (const i of arr) {
        sum += i;
      }
      return sum;
    }
  };

  const selectionRange = {
    startDate: startDate ? startDate : new Date(),
    endDate: endDate ? endDate : new Date(),
    key: 'selection',
  };

  const handleSelect = (ranges) => {
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
  };

  useEffect(() => {
    let type = 'Surgeon';
    if (context.auth.getCurrentUser().is_hologic) {
      type = 'Procedure';
    }

    let extraParams = {};
    if (startDate) {
      extraParams['conversation_start_date'] = startDate.toString();
    }
    if (endDate) {
      extraParams['conversation_end_date'] = endDate.toString();
    }
    getPatientPartners(
      type,
      null,
      null,
      null,
      null,
      limitFiveMentors,
      (data) => {
        setPatientPartners(data);
      },
      true,
      extraParams
    );
  }, [startDate, endDate, limitFiveMentors]);

  let totalPatientConnections = 0;
  patientPartners.forEach((pp) => {
    totalPatientConnections += pp.num_patient_connections;
  });

  useEffect(() => {
    getSurgeonReferredMentorMatches(
      {
        ...(startDate !== '' && { start_date: startDate.toString() }),
        ...(endDate !== '' && { end_date: endDate.toString() }),
      },
      (data) => {
        setSurgeonReferredMentorMatches(data);
        if (data !== undefined) {
          setConversationRating(
            data
              .map((s) => s.conversation_rating)
              .filter((n) => n !== null)
              .map((n) => parseInt(n))
          );
          setIsLoading(false);
        }
      }
    );
  }, [startDate, endDate]);

  useEffect(() => {
    getNumReferrals(
      {
        ...(startDate !== '' && { start_date: startDate.toString() }),
        ...(endDate !== '' && { end_date: endDate.toString() }),
      },
      (data) => {
        setNumReferrals(data.count);
      }
    );
  }, [startDate, endDate]);

  useEffect(() => {
    getConnectedPatients(
      {
        ...(startDate !== '' && { start_date: startDate.toString() }),
        ...(endDate !== '' && { end_date: endDate.toString() }),
      },
      (data) => {
        setNumConnectedPatients(data.patient_count);
      }
    );
  }, [startDate, endDate]);

  let totalFinishedPostConversationSurvey = [];
  totalFinishedPostConversationSurvey =
    surgeonReferredMentorMatches.length > 0 &&
    surgeonReferredMentorMatches.filter((s) => s.post_survey_complete || s.ready_for_next_step === 'Yes');

  let totalReadyForNextStep = [];
  totalReadyForNextStep =
    surgeonReferredMentorMatches.length > 0 &&
    surgeonReferredMentorMatches.filter((s) => s.ready_for_next_step === 'Yes' || s.ready_for_next_step === 'Maybe');
  let totalAppointmentRequested = [];
  totalAppointmentRequested =
    surgeonReferredMentorMatches.length > 0 && surgeonReferredMentorMatches.filter((s) => s.appointment_requested);
  let patientConnections = [];
  patientConnections = surgeonReferredMentorMatches.filter((s) => s.patient_connections);

  const setSortByWrapper = (type) => {
    if (sortBy === type) {
      setSortBy(`${type}-desc`);
    } else if (sortBy === `${type}-desc`) {
      setSortBy('');
    } else if (sortBy !== type) {
      setSortBy(type);
    }
  };

  const sortByMethod = () => {
    switch (sortBy) {
      case 'connections':
      case 'connections-desc':
        return patientPartners.sort((a, b) => (a.num_patient_connections > b.num_patient_connections ? 1 : -1));
      case 'rating':
      case 'rating-desc':
        return patientPartners.sort((a, b) => (a.avg_rating > b.avg_rating ? 1 : -1));
      case 'messages':
      case 'messages-desc':
        return patientPartners.sort((a, b) => (a.avg_messages > b.avg_messages ? 1 : -1));

      default:
        return sortedPatientPartners;
    }
  };
  let sortedPatientPartners = [];
  sortedPatientPartners = sortBy.includes('desc') ? sortByMethod().reverse() : sortByMethod();

  const toggleDropdown = () => {
    setShowDateRange(!showDateRange);
  };

  const ref = React.useRef(null);
  useClickAway(ref, () => {
    setShowDateRange(false);
  });

  if (showDateRange && isScrolling) {
    setShowDateRange(false);
  }

  const procedureWord = context.auth.getCurrentUser().is_reactiv8 ? 'therapy' : 'procedure';

  const capitalize = (s) => {
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const renderKey = (color, text) => {
    return (
      <div className='row'>
        <div className='col-2'>
          <div style={{ background: color }} className={styles.key}></div>
        </div>
        <div className='col-10'>
          <p className={styles.bold_black_text}>{text}</p>
        </div>
      </div>
    );
  };

  const patientPartnerRowClick = (profilePath) => () => {
    window.open(profilePath);
  };

  const renderReferralsMadePanel = () => {
    return (
      <div className='content_container'>
        <ReactTooltip id='appointmentRequested' place='top'>
          <p style={{ color: '#fff', fontSize: '14px', fontWeight: '600', lineHeight: '16px' }}>
            This is the number of people who requested an appointment with a physician.
          </p>
        </ReactTooltip>
        {numReferrals > 0 && <p className={styles.blue_text}>{numReferrals}</p>}
        {numReferrals === 0 && <div className={styles.blue_text}>no data</div>}
        {numReferrals === 1 ? (
          <p data-tip data-for='appointmentRequested' className={styles.black_text}>
            physicial referral made
          </p>
        ) : (
          <p data-tip data-for='appointmentRequested' className={styles.black_text}>
            physicial referrals made
          </p>
        )}
      </div>
    );
  };

  const renderReadyForNextStepPanel = () => {
    const toolTipText = context.auth.getCurrentUser().is_xcopri
      ? 'Patients who are planning or may be planning to start or continue treatment with XCOPRI.'
      : 'This is the number of people who answered they were ready for the next step on a survey.';
    const readyForNextStepText = context.auth.getCurrentUser().is_xcopri
      ? 'will start/continue treatment'
      : 'patient(s) ready for the next step';
    return (
      <div className='content_container'>
        <ReactTooltip id='readyForNextStep' place='top'>
          <p style={{ color: '#fff', fontSize: '14px', fontWeight: '600', lineHeight: '16px' }}>{toolTipText}</p>
        </ReactTooltip>
        {surgeonReferredMentorMatches.length > 0 && (
          <p className={styles.blue_text}>
            {totalReadyForNextStep.length} / {totalFinishedPostConversationSurvey.length}
          </p>
        )}
        {surgeonReferredMentorMatches.length === 0 && <div className={styles.blue_text}>no data</div>}
        <p data-tip data-for='readyForNextStep' className={styles.black_text}>
          {readyForNextStepText}
        </p>
      </div>
    );
  };

  const colors = [
    '#74CCD3',
    '#314D69',
    '#DDF7F9',
    '#188F8B',
    '#80BBB8',
    '#5A7284',
    '#E0F7F8',
    '#AAD2CF',
    '#FFE5CA',
    '#909090',
    '#BCBCBC',
  ];

  const renderBar = (title, yTitle, xTitle, dta) => {
    if (!dta) {
      return <></>;
    }

    const options = {
      indexAxis: 'y',
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: title,
        },
        tooltip: {
          callbacks: {
            title: (context) => {
              return nonTrunctedLabels[context[0].dataIndex];
            },
            label: (context) => {
              return ' ' + context.formattedValue;
            },
          },
        },
      },
      scales: {
        y: {
          display: true,
          title: {
            display: true,
            color: '#000000',
            text: yTitle,
            padding: 10,
          },
          ticks: {
            font: {
              size: 10,
            },
          },
        },
        x: {
          display: true,
          title: {
            display: true,
            color: '#000000',
            text: xTitle,
          },
          ticks: {
            precision: 0,
          },
        },
      },
    };

    let labels = [];
    let values = [];
    let nonTrunctedLabels = [];

    Object.keys(dta).forEach((label) => {
      let truncatedLabel = label.length > 35 ? label.substring(0, 35) + '...' : label;
      labels.push(truncatedLabel);
      nonTrunctedLabels.push(label); // for tooltip
      values.push(dta[label]);
    });

    const data = {
      labels,
      nonTrunctedLabels,
      datasets: [
        {
          data: values,
          borderColor: colors,
          backgroundColor: colors,
        },
      ],
    };

    return <Bar options={options} data={data} />;
  };

  const renderMentorConversationTopics2 = () => {
    let data = surgeonReferredMentorMatches.map((s) => s.conversation_topics).flat();
    if (
      context.auth.getCurrentUser().is_hologic ||
      context.auth.getCurrentUser().is_xcopri ||
      context.auth.getCurrentUser().is_ossio ||
      context.auth.getCurrentUser().is_medtronic
    ) {
      data = surgeonReferredMentorMatches.map((s) => s.mentor_conversation_topics).flat();
    }

    const occurrences = data
      .filter((n) => n)
      .reduce(function (acc, curr) {
        return acc[curr] ? ++acc[curr] : (acc[curr] = 1), acc;
      }, {});

    return renderBar('Topics your mentors help patients with', 'Topics', 'Number of patients', occurrences);
  };

  const renderMentorConversationTopics = () => {
    let categories = [
      { color: '#00ACB7', title: `Pros and cons of the ${procedureWord}` },
      { color: '#0085FF', title: `${capitalize(procedureWord)} preparation tips` },
      {
        color: '#57CC99',
        title: context.auth.getCurrentUser().is_reactiv8
          ? 'How ReActiv8 therapy can help'
          : 'Tips for finding the best doctor',
      },
      {
        color: '#FF6361',
        title: context.auth.getCurrentUser().is_reactiv8 ? `Authorization process and helpful tips` : `Recovery tips`,
      },
    ];

    if (context.auth.getCurrentUser().is_reactiv8_aus) {
      categories = [
        { color: '#00ACB7', title: `Therapy preparation tips` },
        { color: '#0085FF', title: `Unit and therapy sensation` },
        { color: '#57CC99', title: `Recovery process and recommendations` },
        { color: '#FF6361', title: `Previous symptoms and result details` },
        { color: '#80BBB8', title: `General questions and support` },
      ];
    }

    if (context.auth.getCurrentUser().is_hologic) {
      categories = [
        { color: '#00ACB7', title: `Coping with a fibroid diagnosis` },
        { color: '#0085FF', title: `Tips for managing symptoms` },
        { color: '#57CC99', title: `Fibroid treatment options` },
        { color: '#FF6361', title: `Tips for finding a doctor` },
      ];
    }

    if (context.auth.getCurrentUser().is_vonjo) {
      categories = [
        { color: '#00ACB7', title: `Ways to cope with an MF diagnosis` },
        { color: '#0085FF', title: `Tips for managing symptoms` },
        { color: '#57CC99', title: `Personal insight on emotional impact and how to deal with it` },
        { color: '#FF6361', title: `Advice on creating/maintaining a support system` },
        { color: '#80BBB8', title: `Specific questions about treatment` },
      ];
    }

    if (context.auth.getCurrentUser().is_xcopri) {
      categories = [
        { color: '#00ACB7', title: `Guidance on symptom management` },
        { color: '#0085FF', title: `Coping with or managing side effects` },
        { color: '#57CC99', title: `Personal insights into emotional impact and coping strategies` },
        { color: '#FF6361', title: `Advice on building and maintaining a support system` },
        { color: '#80BBB8', title: `Specific questions about treatment` },
        { color: '#ADF7B6', title: `Tips on pharmacy support` },
      ];
    }

    const renderKeys = () => {
      return categories.map((c) => {
        return (
          <>
            {renderKey(c.color, c.title)}
            <Divider />
          </>
        );
      });
    };

    let data = surgeonReferredMentorMatches.map((s) => s.conversation_topics && s.conversation_topics.join(','));
    if (context.auth.getCurrentUser().is_hologic || context.auth.getCurrentUser().is_xcopri) {
      data = surgeonReferredMentorMatches.map(
        (s) => s.mentor_conversation_topics && s.mentor_conversation_topics.join(',')
      );
    }

    const categoryCounts = categories.map((c) => {
      const categoryCount = data.reduce((total, d) => {
        if (d && d.includes(c.title)) {
          return total + 1;
        }
        return total;
      }, 0);

      return categoryCount;
    });

    const total = categoryCounts.reduce((a, b) => {
      return a + b;
    }, 0);

    const graphicData = categoryCounts.map((c) => {
      return {
        x: c > 0 ? ((c / total) * 100).toFixed(2) + ' %' : null,
        y: c,
      };
    });

    return (
      <div className='row'>
        <div className='col-lg-6'>{renderKeys()}</div>
        <div className='col-lg-6'>
          <VictoryPie
            animate={{ easing: 'bounce' }}
            data={graphicData}
            colorScale={categories.map((c) => c.color)}
            style={{ labels: { fontSize: 16, fill: 'white', fontFamily: 'HK Grotesk', fontWeight: '600' } }}
            labelRadius={50}
          />
        </div>
      </div>
    );
  };

  return (
    <div className='container marg-bottom-50'>
      <div className='row'>
        <h1 className={`${styles.header} col-lg-6`}>Insights</h1>
        <div ref={ref} style={{ display: 'flex', justifyContent: 'flex-end' }} className='col-lg-5'>
          <button className='content_container container' onClick={toggleDropdown}>
            <div className='row'>
              <img
                alt='date range'
                style={{ paddingLeft: '10px' }}
                src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/icons/calendar.svg'
              />
              {startDate === '' && endDate === '' ? (
                <p className='col-10'>Select a date range</p>
              ) : (
                <p className='col-10'>{`${startDate.getMonth() + 1 + '/' + startDate.getDate() + '/' + startDate.getFullYear()} - ${endDate.getMonth() + 1 + '/' + endDate.getDate() + '/' + endDate.getFullYear()}`}</p>
              )}
              <svg
                className={`marg-top-10 ${showDateRange ? styles1.arrow_active_flipped : styles1.arrow_active}`}
                width='15'
                height='10'
                viewBox='0 0 15 10'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path d='M1 1L7.27586 8L14 1' stroke='#59B6BE' strokeWidth='1.5' strokeLinecap='round' />
              </svg>
            </div>
          </button>
          {showDateRange && (
            <div
              style={{ borderRadius: '16px', boxShadow: '0px 1px 2px rgba(0, 14, 51, 0.25)' }}
              className='popover marg-top-100'
            >
              <DateRangePicker
                ranges={[selectionRange]}
                onChange={handleSelect}
                rangeColors={['#59B6BE', '#DDF7F9']}
                showSelectionPreview={true}
                direction='horizontal'
                months={1}
                shownDate={startDate && startDate}
                preview={{ startDate: startDate, endDate: endDate }}
              />
            </div>
          )}
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-4'>
          <h4 className={styles.small_header}>Patient connection conversions</h4>
          <div className='content_container'>
            <ReactTooltip id='numConnectedPatients' place='top'>
              <p style={{ color: '#fff', fontSize: '14px', fontWeight: '600', lineHeight: '16px' }}>
                This is the number of people who initiated a conversation with a mentor.
              </p>
            </ReactTooltip>
            {numConnectedPatients > 0 && <div className={styles.blue_text}>{numConnectedPatients}</div>}
            {numConnectedPatients === 0 && <div className={styles.blue_text}>no data</div>}
            {numConnectedPatients > 1 ? (
              <p
                data-tip
                data-for='patientsConnectedWithMentor'
                id='patientsConnectedWithMentor'
                className={`row justify-content-center ${styles.black_text}`}
              >
                patient connected with mentors
              </p>
            ) : (
              <p
                data-tip
                data-for='patientsConnectedWithMentor'
                id='patientsConnectedWithMentor'
                className={`row justify-content-center ${styles.black_text}`}
              >
                patients connected with mentors
              </p>
            )}
          </div>
          {user.is_hologic && renderReferralsMadePanel()}
          {!user.is_hologic && renderReadyForNextStepPanel()}
          <div className='content_container'>
            <ReactTooltip id='conversationRating' place='top'>
              <p style={{ color: '#fff', fontSize: '14px', fontWeight: '600', lineHeight: '16px' }}>
                This is the average conversation rating provided by users on a survey.
              </p>
            </ReactTooltip>
            {!isLoading && countSum(conversationRating) > 0 && (
              <div style={{ display: 'flex' }} className='justify-content-center'>
                <StarRating averageRating={Math.round(countSum(conversationRating) / conversationRating.length)} />
              </div>
            )}
            {surgeonReferredMentorMatches.length > 0 && countSum(conversationRating) === 0 && (
              <div className={styles.blue_text}>0</div>
            )}
            {surgeonReferredMentorMatches.length === 0 && <div className={styles.blue_text}>no data</div>}
            <p data-tip data-for='conversationRating' className={`row justify-content-center ${styles.black_text}`}>
              avg. conversation satisfaction rating
            </p>
          </div>
        </div>
        <div className={`col-lg-8 ${styles.pie_chart_cont} `}>
          <div className='content_container'>
            {isLoading && <Skeleton shape={'pieChart'} />}
            <div className={`${styles.gray_header} marg-bottom-40`}>Topics your mentors help patients with</div>
            {!isLoading && surgeonReferredMentorMatches.length === 0 && (
              <div className='col'>
                <div className='container'>
                  <div className='row'>
                    <div className='col'>
                      <div className='row marg-top-20'>
                        <div style={{ background: '#57CC99' }} className={styles.key}></div>
                        <div className={styles.empty_bar}></div>
                      </div>
                      <div className='row marg-top-20'>
                        <div style={{ background: '#00ACB7' }} className={styles.key}></div>
                        <div className={styles.empty_bar}></div>
                      </div>
                      <div className='row marg-top-20'>
                        <div style={{ background: '#FF6361' }} className={styles.key}></div>
                        <div className={styles.empty_bar}></div>
                      </div>
                      <div className='row marg-top-20'>
                        <div style={{ background: '#0085FF' }} className={styles.key}></div>
                        <div className={styles.empty_bar}></div>
                      </div>
                    </div>
                    <div className={`col marg-bottom-50 ${styles.empty_pie_chart}`}>
                      <p style={{ color: '#828282' }}>No topics yet!</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!isLoading && surgeonReferredMentorMatches.length > 0 && renderMentorConversationTopics2()}
          </div>
        </div>
      </div>

      {!isLoading && (
        <>
          <div className={`${styles1.roster_container_wrapper} de-pad`}>
            <div className={styles1.mentor_connect_heading} style={{ marginTop: '20px' }}>
              <h4 style={{ margin: '0px' }} className={styles.small_header}>
                Mentor Health
              </h4>
            </div>
            <div className='row'>
              <div className='col'>
                <div className='content_container'>
                  {patientPartners.length > 0 ? (
                    <div className={`${styles.blue_text} row justify-content-center`}>{patientPartners.length}</div>
                  ) : (
                    <div className={`${styles.blue_text} row justify-content-center`}>no data</div>
                  )}
                  <p className='row justify-content-center'>active mentors</p>
                </div>
              </div>
              <div className='col'>
                <div className='content_container'>
                  <ReactTooltip id='patientsConnectedWithMentor' place='top'>
                    <p style={{ color: '#fff', fontSize: '14px', fontWeight: '600', lineHeight: '16px' }}>
                      This is the number of people who initiated a conversation with a mentor.
                    </p>
                  </ReactTooltip>
                  {numConnectedPatients > 0 ? (
                    <div className={`${styles.blue_text} row justify-content-center`}>{numConnectedPatients}</div>
                  ) : (
                    <div className={`${styles.blue_text} row justify-content-center`}>no data</div>
                  )}
                  <p
                    data-tip
                    data-for='patientsConnectedWithMentor'
                    id='patientsConnectedWithMentor'
                    className='row justify-content-center'
                  >
                    patients connected with mentors
                  </p>
                </div>
              </div>
            </div>
            <div className={`${styles1.roster_container}`}>
              {!isMobile && (
                <>
                  <div className='row'></div>
                  <div className={`${styles1.roster_row} ${styles1.roster_row_header}`}>
                    <div className='col-3'>
                      <p className={`no-select ${styles1.hover_col}`}>Mentor Name</p>
                    </div>
                    <div className='col-3'>
                      <p className={`no-select ${styles1.hover_col}`}>Mentor Email</p>
                    </div>
                    <div className='col-2'>
                      <p
                        className={`no-select ${styles1.hover_col} ${sortBy === 'connections' ? styles1.col_active : sortBy === 'connections-desc' ? styles1.col_active_flipped : styles1.col_null}`}
                        onClick={() => setSortByWrapper('connections')}
                      >
                        Total patient conversations
                      </p>
                      <svg
                        className={
                          sortBy === 'connections'
                            ? styles1.arrow_active
                            : sortBy === 'connections-desc'
                              ? styles1.arrow_active_flipped
                              : styles1.arrow_null
                        }
                        width='15'
                        height='10'
                        viewBox='0 0 15 10'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path d='M1 1L7.27586 8L14 1' stroke='#59B6BE' strokeWidth='1.5' strokeLinecap='round' />
                      </svg>
                    </div>
                    <div className='col-2'>
                      <p
                        className={`no-select ${styles1.hover_col} ${sortBy === 'rating' ? styles1.col_active : sortBy === 'rating-desc' ? styles1.col_active_flipped : styles1.col_null}`}
                        onClick={() => setSortByWrapper('rating')}
                      >
                        Avg. rating
                      </p>
                      <svg
                        className={
                          sortBy === 'rating'
                            ? styles1.arrow_active
                            : sortBy === 'rating-desc'
                              ? styles1.arrow_active_flipped
                              : styles1.arrow_null
                        }
                        width='15'
                        height='10'
                        viewBox='0 0 15 10'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path d='M1 1L7.27586 8L14 1' stroke='#59B6BE' strokeWidth='1.5' strokeLinecap='round' />
                      </svg>
                    </div>
                    <div className='col-2'>
                      <p
                        className={`no-select ${styles1.hover_col} ${sortBy === 'messages' ? styles1.col_active : sortBy === 'messages-desc' ? styles1.col_active_flipped : styles1.col_null}`}
                        onClick={() => setSortByWrapper('messages')}
                      >
                        Total number of messages
                      </p>
                      <svg
                        className={
                          sortBy === 'messages'
                            ? styles1.arrow_active
                            : sortBy === 'messages-desc'
                              ? styles1.arrow_active_flipped
                              : styles1.arrow_null
                        }
                        width='15'
                        height='10'
                        viewBox='0 0 15 10'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path d='M1 1L7.27586 8L14 1' stroke='#59B6BE' strokeWidth='1.5' strokeLinecap='round' />
                      </svg>
                    </div>
                  </div>
                </>
              )}
              {patientPartners.length === 0 && (
                <div className={`container`}>
                  <div className='row justify-content-center'>
                    <img
                      alt='crying face'
                      className='marg-top-20 marg-bottom-20'
                      src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/icons/crying-emoji.jpg'
                    />
                  </div>
                  <div className='row justify-content-center'>
                    <h2 className={styles.small_header}>No mentors!</h2>
                  </div>
                  <div className='row justify-content-center'>
                    <div className='col-7 marg-bottom-50'>
                      <p className={styles.normal_text}>
                        It doesn’t look like any mentors have been invited to help patients.
                      </p>
                      <p className={`marg-top-20 ${styles.normal_text}`}>
                        Contact your customer success manager or
                        <a className={styles.blue_link} href='mailto: support@patientpartner.com'>
                          support@patientpartner.com
                        </a>
                        to get started!
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {patientPartners.length !== 0 &&
                patientPartners.map((p) => (
                  <animated.div>
                    {!isMobile && (
                      <div className={`${styles1.roster_row}`}>
                        <div
                          className={`h-100 ${styles1.roster_row_hover}`}
                          onClick={patientPartnerRowClick(p.profile_about_me_path)}
                        >
                          <div className={`col-2 cursor-pointer ${styles1.roster_col}`}>
                            <p>
                              {p.name} {p.inactive ? '(inactive)' : ''}
                            </p>
                          </div>
                          <div className={`col-4 cursor-pointer ${styles1.roster_col}`}>
                            <p>{p.email}</p>
                          </div>
                          <div className={`col-2 cursor-pointer ${styles1.roster_col}`}>
                            <p>{p.num_patient_connections}</p>
                          </div>
                          <div className={`col-2 cursor-pointer ${styles1.roster_col} `}>
                            {p.avg_rating > 0 ? <StarRating averageRating={Math.round(p.avg_rating)} /> : <p>n/a</p>}
                          </div>
                          <div className={`col-2 cursor-pointer ${styles1.roster_col}`}>
                            <p>{p.total_messages}</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </animated.div>
                ))}
              {/*
              patientPartners.length >= 5 &&
              <div className='row justify-content-center'>
              {limitFiveMentors &&
                <button onClick={() => setLimitFiveMentors(!limitFiveMentors)} className='tertiary'>See all mentors</button>
              }
              </div>
              */}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default IsScrolling(ProviderInsights);
