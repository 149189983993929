import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Modal from '../modal';
import WebinarPopup from '../webinar_popup';
import AgreeToTerms from '../ui_kit/utilities/agree_to_terms';
import Context from '../../utils/context';

export default function AcceptPrivacyModal() {
  let location = useLocation();
  const context = useContext(Context);
  const [showAcceptPrivacyModal, setShowAcceptPrivacyModal] = useState(false);
  const [acceptedPrivacy, setAcceptedPrivacy] = useState(false);

  const onPrivacyContinue = (e) => {
    if (!acceptedPrivacy) {
      e.preventDefault();
      return;
    }
    setShowAcceptPrivacyModal(false);
    context.auth.userUpdate({ accepted_privacy: true });
  };

  useEffect(() => {
    if (context.auth.isLoggedIn() && !context.auth.getCurrentUser().accepted_privacy) {
      setShowAcceptPrivacyModal(true);
    }
  }, [location]);

  return (
    <>
      {showAcceptPrivacyModal && (
        <Modal
          className='pp-modal wl-webinar-modal'
          content={
            <WebinarPopup
              headline='Changes to our Terms of Use'
              middleContent={
                <>
                  <p>
                    PatientPartner has updated the{' '}
                    <Link target='_blank' to='/terms' className='terms-link' style={{ color: '#73CCD3 ' }}>
                      Terms of Service
                    </Link>{' '}
                    and{' '}
                    <Link target='_blank' to='/privacy' className='terms-link' style={{ color: '#73CCD3' }}>
                      Privacy Policy and Authorizations
                    </Link>
                    . Please read and agree to the updated policies.
                  </p>
                  <AgreeToTerms acceptedTerms={acceptedPrivacy} setAcceptedTerms={setAcceptedPrivacy} />
                </>
              }
              wrapperClassnames={`popup-bg`}
              actionContent={
                <a onClick={(e) => onPrivacyContinue(e)}>
                  {' '}
                  <button disabled={!acceptedPrivacy} className='wb-cta'>
                    Continue
                  </button>
                </a>
              }
              image='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/popups/IG+Live+WL+Pop+Up.jpg'
              leftClassnames={'text-center whole-width'}
              showRightSide={false}
              headerClassnames={`privacy-header`}
            />
          }
          hideClose
          backdrop={`static`}
        />
      )}
    </>
  );
}
