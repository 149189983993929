import React from 'react';

export default function ConfirmationPage({
  head = 'We have everything we need to find you the right doctor!',
  para = 'A member of our team will reach out to share more about the specialists we find for you within the next 1-2 business days.',
  para1 = (
    <>
      If you have any questions, don’t hesitate to contact us by clicking the button below or emailing{' '}
      <a style={{ color: '#59B6BE' }} href='mailto:support@patientpartner.com'>
        support@patientpartner.com
      </a>
      .
    </>
  ),
}) {
  return (
    <div className='row'>
      <div className='col-2'></div>
      <div className='col-8'>
        <h3 className='text-left mobile-marg-20 marg-bottom-30'>{head}</h3>
        <p className='marg-bottom-30'>{para}</p>
        <p className='marg-bottom-30'>{para1}</p>
        <button className='col-8' type='submit'>
          <a style={{ fontWeight: '700' }} href='tel:+16192681112'>
            Text or call us
          </a>
        </button>
      </div>
      <div className='col-2'></div>
    </div>
  );
}
