import React, { useState, useEffect, useContext } from 'react';
import { Link, Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import Context from '../../../utils/context';
import SimpleSlidingMenu from '../../ui_kit/utilities/simple_sliding_menu';
import ProviderInsights from '../insights';
import PhysicianRoster from '../physician_roster';
import CommunityInsights from '../community_insights';
import ProviderPatients from '../patients';
import PatientDemographics from '../patient_demographics';
import MentorConnect from '../patients/mentor_connect';
import RestrictedRoute from '../../../route_helpers/RestrictedRoute';
import styles from './index.module.css';

export default function ProviderHome() {
  let { path } = useRouteMatch();
  let isMobile = useContext(Context).viewport.getIsMobile();
  let currentUser = useContext(Context).auth.getCurrentUser();

  let menuLinks = [
    {
      icon: (
        <img
          alt='insights'
          src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/providers/insights.svg'
        />
      ),
      text: 'Insights',
      url: '/provider/insights',
    },
    {
      icon: (
        <img
          alt='mentorconnect'
          src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/providers/mentorconnect.svg'
        />
      ),
      text: 'MentorConnect',
      url: '/provider/mentor-connect',
    },
  ];

  if (
    !currentUser.is_nybg &&
    !currentUser.is_reactiv8 &&
    !currentUser.is_morehouse &&
    !currentUser.is_ossio &&
    !currentUser.is_medtronic
  ) {
    menuLinks.push({
      icon: (
        <img
          alt='referrals'
          src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/providers/referrals.svg'
        />
      ),
      text: 'New Referrals',
      url: '/provider/patients',
    });
  }

  let menuItems = [
    {
      linkText: 'Insights',
      idToScrollTo: 'insights',
      href: `${path}/insights`,
      pendoTag: 'profile page navigation - Insights',
    },
    {
      linkText: 'Mentor Connect',
      idToScrollTo: 'mentor-connect',
      href: `${path}/mentor-connect`,
      pendoTag: 'profile page navigation - mentor connect',
    },
  ];

  if (currentUser.is_hologic) {
    menuLinks = [
      {
        icon: (
          <img
            alt='insights'
            src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/providers/insights.svg'
          />
        ),
        text: 'Insights',
        url: '/provider/insights',
      },
      {
        icon: (
          <img
            alt='referrals'
            src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/providers/referrals.svg'
          />
        ),
        text: 'Physician roster',
        url: '/provider/physician-roster',
      },
      {
        icon: (
          <img
            alt='referrals'
            src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/providers/referrals.svg'
          />
        ),
        text: 'Community insights',
        url: '/provider/community-insights',
      },
      {
        icon: (
          <img
            alt='referrals'
            src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/providers/referrals.svg'
          />
        ),
        text: 'Patient Demographics',
        url: '/provider/patient-demographics',
      },
    ];
  }

  if (currentUser.is_vonjo || currentUser.is_xcopri) {
    menuLinks = [
      {
        icon: (
          <img
            alt='insights'
            src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/providers/insights.svg'
          />
        ),
        text: 'Insights',
        url: '/provider/insights',
      },
      {
        icon: (
          <img
            alt='referrals'
            src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/providers/referrals.svg'
          />
        ),
        text: 'Patient Demographics',
        url: '/provider/patient-demographics',
      },
    ];
  }

  const location = useLocation();
  let defaultSelectedMenuItem = menuItems[0];
  menuItems.forEach((item) => {
    if (item.href === location.pathname) {
      defaultSelectedMenuItem = item;
    }
  });
  const [selectedMenuItem, setSelectedMenuItem] = useState(defaultSelectedMenuItem);

  if (isMobile)
    return (
      <div className={`container-fluid ${styles.outer_wrapper}`}>
        <div className='container marg-top-30'>
          <div className='row'>
            <div className='col'>
              <SimpleSlidingMenu
                menuItems={menuItems}
                setMarker={selectedMenuItem}
                setMarkerItem={setSelectedMenuItem}
              />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <Switch>
              <Route exact path={`${path}/insights`}>
                <ProviderInsights />
              </Route>
              <Route exact path={`${path}/physician-roster`}>
                <PhysicianRoster />
              </Route>
              <Route exact path={`${path}/community-insights`}>
                <CommunityInsights />
              </Route>
              <Route exact path={`${path}/mentor-connect`}>
                <MentorConnect />
              </Route>
              <Route exact path={`${path}/patients`}>
                <ProviderPatients />
              </Route>
              <Route exact path={path}></Route>
            </Switch>
          </div>
        </div>
      </div>
    );

  return (
    <div className={`container-fluid ${styles.outer_wrapper}`}>
      <div className='row'>
        <div className='col-2'>
          <nav className={styles.left_menu}>
            {menuLinks.map((link) => (
              <Link
                className={`${styles.menu_link} ${window.location.pathname === link.url && styles.selected}`}
                to={link.url}
              >
                {link.icon}
                <p>{link.text}</p>
              </Link>
            ))}
          </nav>
        </div>
        <div className='col-9'>
          <Switch>
            <Route exact path={`${path}/insights`}>
              <ProviderInsights />
            </Route>
            <Route exact path={`${path}/physician-roster`}>
              <PhysicianRoster />
            </Route>
            <Route exact path={`${path}/community-insights`}>
              <CommunityInsights />
            </Route>
            <Route exact path={`${path}/patient-demographics`}>
              <PatientDemographics />
            </Route>
            <Route exact path={`${path}/mentor-connect`}>
              <MentorConnect />
            </Route>
            <RestrictedRoute
              exact
              path={`${path}/patients`}
              component={ProviderPatients}
              restrictedTypes={['is_xcopri']}
              redirectPath='insights'
            />
            <Route exact path={path}></Route>
          </Switch>
        </div>
        <div className='col-1'></div>
      </div>
    </div>
  );
}
