import React from 'react';
import { Link } from 'react-router-dom';
import './footer.css';

export default function Footer({ fullFooter = true, bgColor = '#173959', columnHeaderStyles = null }) {
  return (
    <footer className='footer' style={{ backgroundColor: bgColor }}>
      <div className='container'>
        <div className='footer-top'>
          <div className='row'>
            <div className='col-md-6 wow fadeInUp' data-wow-delay='0.2s'>
              <div className='footer-innr'>
                <div className='foot-logo'>
                  <Link to='/' className='logo'>
                    {' '}
                    <img
                      src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/patient-partner-logo-light.png'
                      alt='patientpartnerlogo'
                      className='img-fluid'
                      data-gtm-click-label='Footer Logo'
                    />
                  </Link>
                </div>
                {/* <p className="foot-para">Supporting the patient community one surgery at a time</p> */}
              </div>
            </div>
          </div>
          {fullFooter ? (
            <div className='row'>
              <div className='col-md-3' data-wow-delay='0.8s'>
                <div className='footer-innr'>
                  <div className='footer-menu'>
                    <p style={columnHeaderStyles}>For Patients</p>
                    <ul>
                      <li>
                        <Link to='/get-started/patient-partner-match' data-gtm-click-label='Footer PatientPartner'>
                          Find a mentor
                        </Link>
                      </li>
                      <li>
                        <Link to='/patient-partner-agreement' data-gtm-click-label='Footer Participation agreement'>
                          Participation agreement
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className='col-md-3' data-wow-delay='0.8s'>
                <div className='footer-innr'>
                  <div className='footer-menu'>
                    <p style={columnHeaderStyles}>For Mentors</p>
                    <ul>
                      <li>
                        <Link to='/for-mentors' data-gtm-click-label='Footer For Mentors'>
                          Become a PatientPartner mentor
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className='col-md-3' data-wow-delay='0.8s'>
                <div className='footer-innr'>
                  <div className='footer-menu'>
                    <p style={columnHeaderStyles}>Partner With Us</p>
                    <ul>
                      <li>
                        <Link to='/for-providers' data-gtm-click-label='Footer For Providers'>
                          Become a PatientPartner provider
                        </Link>
                      </li>
                      <li>
                        <Link to='/industry-partners' data-gtm-click-label='Footer Industry Partners'>
                          Industry Partners
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className='col-md-3' data-wow-delay='0.6s'>
                <div className='footer-innr'>
                  <div className='footer-menu'>
                    <p style={columnHeaderStyles}>Our Company</p>
                    <ul>
                      <li>
                        <Link to='/contact-us' data-gtm-click-label='Footer Contact'>
                          Contact us
                        </Link>
                      </li>
                      <li>
                        <Link to='/how-it-works' data-gtm-click-label='Footer How It Works'>
                          How it works
                        </Link>
                      </li>
                      <li>
                        <Link to='/press-room' data-gtm-click-label='Footer Press Room'>
                          Press Room
                        </Link>
                      </li>
                      <li>
                        <a
                          target='_blank'
                          href='https://careers.patientpartner.com'
                          data-gtm-click-label='Footer Careers'
                        >
                          Careers
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>

        <div className='footer-bottom text-center'>
          <div className='row'>
            <div className='col-md-9 wow fadeInUp' data-wow-delay='0.2s'>
              <p className='copyright-txt'>
                &copy; {new Date().getFullYear()} rightdevice, Inc. dba PatientPartner All right reserved. |{' '}
                <Link data-gtm-click-label='Footer Terms of Service' to='/terms'>
                  Terms of service
                </Link>{' '}
                |{' '}
                <Link data-gtm-click-label='Footer Privacy' to='/privacy'>
                  Privacy policy
                </Link>{' '}
                |{' '}
                <Link
                  data-gtm-click-label='Footer Compliance'
                  to='#'
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = 'https://compliance.patientpartner.com';
                  }}
                >
                  Compliance
                </Link>
              </p>
            </div>
            <div className='col-md-3 wow fadeInUp' data-wow-delay='0.2s'>
              <div className='socials'>
                <a target='_blank' href='https://www.facebook.com/PatientPartner1'>
                  <img
                    src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/contact-us/facebook-w.svg'
                    aria-hidden='true'
                    data-gtm-click-label='Footer Facebook'
                  ></img>
                </a>
                <a target='_blank' href='https://twitter.com/patientpartner1'>
                  <img
                    src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/contact-us/twitter-w.svg'
                    aria-hidden='true'
                    data-gtm-click-label='Footer Twitter'
                  ></img>
                </a>
                <a target='_blank' href='https://www.instagram.com/patient_partner/'>
                  <img
                    src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/contact-us/instagram-w.svg'
                    aria-hidden='true'
                    data-gtm-click-label='Footer Instagram'
                  ></img>
                </a>
                <a target='_blank' href='https://in.linkedin.com/company/rightdevice'>
                  <img
                    src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/contact-us/linkedin-w.svg'
                    aria-hidden='true'
                    data-gtm-click-label='Footer Linkedin'
                  ></img>
                </a>
                <a target='_blank' href='https://www.youtube.com/channel/UC5EthG-VCW6xJ6RnTPaYUIQ'>
                  <img
                    src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/contact-us/youtube-w.svg'
                    aria-hidden='true'
                    data-gtm-click-label='Footer Youtube'
                  ></img>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
